//
// reboot.scss
//

body {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-color: #f1f1f1 !important;
  &.loading {
    visibility: hidden;
  }
}

button,
a {
  outline: none !important;
}

// Forms

label {
  font-weight: $font-weight-semibold;
}

// Address
address.address-lg {
  line-height: 24px;
}

b,
strong {
  font-weight: $font-weight-bold;
}
