.field-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.field-wrapper form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.field-wrapper-bom {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.checkboxClassCustom .form-check {
  margin-top: 15px;
}

.button-right {
  float: right;
}

.form-check input[type="checkbox"] {
  padding: 0;
  /* margin-right: 10px; */
  margin-right: 0px;
}

.add_custom_form .form-check:last-child {
  margin-bottom: 20px;
}

.add-new-field {
  background-color: #f2f6fc;
  border: 0.1875rem dashed #d4dae3;
  border-radius: 0.5rem;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-size: medium;
}

/* global css for overwrite existing css starts here */
/* .customTableClass{
  table-layout: fixed;
  width: 100%;
} */
.customTableClass td {
  padding: 0.3rem 0.625rem;
  color: #2A2A2A;
  font-size: 14px;
  border-color: #f5f5f5;
}

.customTableClass th {
  color: #8E8E8E;
  padding: 0.6rem 0.5rem 0.4rem;
  background-color: transparent;
  padding-left: 10px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
}

.customTableClass .badge {
  padding: 8px;
}

.customTableClass thead th .btn.btn-link i {
  font-size: 22px;
  line-height: 1;
}

.customTableClass thead th .btn.btn-link {
  padding: 0;
  line-height: 1;
}

.icon_outer img {
  max-width: 37px;
}

.txt {
  color: #000;
}

.pending_badge {
  background-color: rgb(255 237 78 / 50%);
  color: #7a4a30;
}

.closed_badge {
  background-color: #ffeeee;
  color: #ff5050;
}

.assigned_badge {
  background-color: rgb(114 124 245 / 20%);
  color: #2e3bd7;
}

.delivered_badge {
  background-color: #e4e4e4;
  color: #494949;
}

.open_badge {
  background-color: #e7faf1;
  color: #0ec970;
}

/* status badge color declaration */

.violet_bdge {
  background-color: #f1f2fe;
  color: #727cf5;
}

.green_bdge {
  background-color: #e7faf1;
  color: #0ec970;
}

.yellow_bdge {
  background-color: #fffaee;
  color: #ffcf55;
}

.grey_bdge {
  background-color: #ededed;
  color: #000;
}

.red_bdge {
  background-color: #ffeeee;
  color: #ff5050;
}

.light_grey_bdge {
  background-color: #f3f3f5;
  color: #0d1432;
}

.honey_dew_bdge {
  background-color: #ebffeb;
  color: #3cc13b;
}

.tomato_bdge {
  background-color: #fff8f8;
  color: #ff5959;
}

.dodger_blue_bdge {
  background-color: #f1faff;
  color: #1ea1f2;
}

.charcoal_bdge {
  background-color: #f6f6f6;
  color: #494949;
}

.sun_bdge {
  background-color: #fff0e3;
  color: #ff862f;
}

.midnight_bdge {
  background-color: #f3f3f5;
  color: #0d1432;
}

.action-icon i {
  color: #4b4b4b;
}

.customPagination {
  width: 100%;
  margin: 10px 0 5px;
  padding: 0px 10px;
}

.customPagination label, .customPagination span, .customPagination a.page-link {
  color: #333333;
  font-weight: 700;
  font-size: 14px;
}

.customPagination .page-item.active .page-link {
  background-color: #9a9a9a;
  color: #fafafa;
}

.customFormOuter {
  width: 100%;
  margin: 0 auto;
}

.customFormOuter .card-body {
  padding: 1.1rem;
}

.customFormOuter .form-control,
.customFormOuter .form-select {
  margin-bottom: 15px;
}

.customFormOuter .table td .form-control {
  margin-bottom: 0px;
}

.customFormOuter .table td,
.customFormOuter .table th {
  padding:0.3rem 0.625rem;
}

.customFormOuter label {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #1e1e1e;
}

.customFormOuter h4.page-title {
  color: #727cf5;
  font-size: 1.5rem;
}

.add_machine {
  text-align: right;
  color: #727cf5;
}

.customFormInline {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 9px;
}

.customTitleHead.p-2.card {
  padding: 5px 10px !important;
  margin-bottom: 10px;
}

.customTitleHead h4.page-title {
  color: #1e1e1e;
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 0;
}

.card-body {
  padding: 0rem;
}

body.sidebar-enable .side-nav .menuitem-active a.side-nav-link,
body.sidebar-enable .side-nav a.side-nav-link:hover,
body.sidebar-enable .side-nav a.side-nav-link {
  /* width: 70px !important; */
}

/* body.sidebar-enable .side-nav .menuitem-active a.side-nav-link span{
    display: none !important;
} */

.float-left {
  /* float:left; */
  margin-left:50px;
}

.side-nav li.side-nav-item {
  margin-bottom: 5px;
}

/* .side-nav > li.side-nav-item:nth-child(12) {
  margin-top: 25px;
} */

/* .side-nav > li.side-nav-item:nth-child(13) {
  margin-bottom: 10px;
} */

.side-nav > li.side-nav-item:nth-child(12)::after,
.side-nav > li.side-nav-item:nth-child(13)::after {
  /* content: ""; */
  background: #ededed;
  height: 2px;
  width: 85%;
  margin: 0 auto;
  display: block;
  position: relative;
  top: 10px;
}

.searchIconOuter {
  position: relative;
}

.searchIconOuter input {
  padding-right: 30px;
}

.searchIcon {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 25px;
  color: #dee2e6;
}

.login_logo {
  position: absolute;
  top: 40px;
  left: 40px;
}

.login_page h2 {
  color: #000;
}

.login_page h4 {
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
}

.login_page label {
  color: #000;
  font-weight: 400;
  font-size: 17px;
}

.customCheck label {
  color: #727cf5;
  margin-bottom: 15px;
}

.backIcon {
  font-size: 30px;
  color: #727cf5;
}

.orDiv {
  text-align: center;
  margin: 25px 0;
  position: relative;
}

.orDiv span::before {
  content: "";
  background: #d2d2d2;
  height: 1px;
  width: 44%;
  position: absolute;
  left: 0;
  top: 10px;
}

.orDiv span::after {
  content: "";
  background: #d2d2d2;
  height: 1px;
  width: 44%;
  position: absolute;
  right: 0;
  top: 10px;
}

.logingoogle {
  border: 1px solid #d2d2d2;
  display: inherit;
  width: 100%;
  text-align: center;
  color: #000;
  padding: 7px 0;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
}

.dropzone {
  border-color: #727cf5;
}

.error-message {
  color: red;
  position: relative;
  top: 0px;
  margin: 0;
  font-size: 12px;
  left: -2px;
}

.error-message::before {
  content: "\e9b4";
  font-family: unicons;
  color: red;
  font-size: 15px;
  margin-right: 5px;
}

.error-message:empty {
  display: none;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item
  .side-nav-link {
  width: 70px !important;
  left: 0;
  padding: 10px 5px;
  text-align: center;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item
  .side-nav-link:hover {
    width: 200px !important;
    text-align: left;
    left: 20px;
}

.side-nav-second-level li a i {
  font-size: 1.1rem;
}

.customTableClass .btn.btn-link {
  box-shadow: none;
  outline: none;
}

.button-menu-mobile .uil-right-indent-alt {
  display: none;
}

body.sidebar-enable .uil-left-indent-alt {
  display: none;
}

body.sidebar-enable .uil-right-indent-alt {
  display: block;
}

.Toastify div {
  font-family: "Nunito", sans-serif;
  color: #000;
  font-weight: 600;
}

.smIcon {
  max-height: 20px;
}

/* chat box design  css starts*/

.meesages-list {
  padding: 20px 30px;
  width: 100%;
  background: #fff;
  height: 550px !important;
  overflow-y: scroll;
}

.messageItem {
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 15px;
  padding-left: 70px;
  position: relative;
}

.messageItem .innerMessage {
  background: #e7e8ff;
  display: inline-block;
  padding: 10px;
  position: relative;
  border-radius: 0 12px;
}

.messageItem .innerMessage::before {
  content: "";
  border-top: 0px solid transparent;
  border-right: 20px solid #e7e8ff;
  border-bottom: 15px solid transparent;
  position: absolute;
  left: -18px;
  top: 0;
  border-bottom-right-radius: 5px;
}

.firstLetter {
  width: 50px;
  height: 50px;
  background: #e7e8ff;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 50px;
  top: 0;
  left: 0;
  text-transform: uppercase;
  color: #727cf5;
  font-weight: 600;
}

.ownLetter {
  left: inherit;
  right: 0;
  background: #ececec;
  color: #434343;
}

.ownMessage {
  float: right;
  text-align: end;
  padding-left: 0;
  padding-right: 70px;
}

.messageItem.ownMessage .innerMessage {
  background: #ececec;
  display: inline-block;
  padding: 10px;
  border-radius: 12px 0px;
}

.messageItem.ownMessage .innerMessage::before {
  content: "";
  border-top: 0px solid transparent;
  border-left: 20px solid #ececec;
  border-bottom: 15px solid transparent;
  position: absolute;
  right: -18px;
  left: auto;
  top: 0;
  border-bottom-right-radius: 5px;
  border-right: inherit;
}

.messageItem.messageItem.notificationChatMessage {
  text-align: center;
}

.messageItem.notificationChatMessage .innerMessage {
  background: #f9e8c9;
}

.messageItem.notificationChatMessage .innerMessage::before {
  content: none;
}

.messageItem.notifytextMsg .innerMessage {
  text-align: center;
  background: #f5f5f5;
  padding: 2px 7px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.messageItem.notifytextMsg .innerMessage::before {
  content: none;
}

.messageItem.notifytextMsg span.messageTxt {
  margin: 0;
  font-size: 14px;
}

.chatBoxOuterHght {
  height: 400px;
  width: 100%;
}

.chatHeading {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.chatHeading h3 {
  color: #727cf5;
  font-size: 25px;
  margin: 0;
}

.chats {
  padding: 15px;
}

.channel-list {
  background: rgba(114, 124, 245, 0.1);
}

.time {
  font-size: 13px;
  font-weight: 500;
}

.messageTxt {
  color: #000;
  font-size: 15px;
  margin: 5px 0;
  display: inline-block;
  text-align: left;
}

.sendBtn img {
  max-width: 12px;
  margin-top: -3px;
  margin-left: 2px;
}

.senderPerson {
  color: #777777;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 15px;
}

.messages-input input {
  background: rgba(114, 124, 245, 0.1);
  padding: 12px;
  color: #777777;
}

.messages-input input:focus {
  background: rgba(114, 124, 245, 0.1);
}

.messages-input input::placeholder {
  color: #777777;
}

/* width */
.meesages-list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.meesages-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.meesages-list::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px #727cf5;
  border-radius: 10px;
}

.channel-item.active {
  background: #fff;
  border-color: #fff;
  border-radius: 6px;
}

.removeicon .menu-arrow {
  display: none;
}

.removeicon .side-nav-second-level {
  margin-top: -5px !important;
}

.removeicon a.side-nav-link-ref.side-sub-nav-link,
.removeicon a.side-nav-link-ref.side-sub-nav-link.active {
  padding-right: 10px;
  width: 89%;
}

.removeicon a.side-nav-link-ref.side-sub-nav-link.active {
  margin-top: 5px;
}

.inner-tab .nav.nav-tabs {
  position: absolute;
  right: 0;
  border: 0;
  width: 72%;
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
}

.inner-tab .tab-content {
  margin-top: 20px;
}

.inner-tab .nav-tabs .nav-link {
  border: 0;
  color: #777777;
  font-size: 16px;
  padding-right: 0;
  margin-right: 15px;
  padding-left: 20px;
  position: relative;
}

.inner-tab .nav-tabs .nav-link.disabled {
  opacity: 0.6;
}

.inner-tab .nav-tabs .nav-link.active {
  color: #727cf5;
  font-weight: 700;
  border-bottom: 2px solid #727cf5;
}

.inner-tab .nav-tabs li .nav-link::before {
  font-family: "unicons";
  position: absolute;
  left: 0;
}

.inner-tab .nav-tabs li:nth-child(1) .nav-link::before {
  content: "\eaf2";
}

.inner-tab .nav-tabs li:nth-child(2) .nav-link::before {
  content: "\e94f";
}

.inner-tab .nav-tabs li:nth-child(3) .nav-link::before {
  content: "\f0255";
  font-family: "Material Design Icons";
}

.inner-tab .nav-tabs li:nth-child(4) .nav-link::before {
  content: "\e8c8";
}

.inner-tab .nav-tabs li:nth-child(5) .nav-link::before {
  content: "\e9cd";
}

.inner-tab .nav-tabs li:nth-child(6) .nav-link::before {
  content: "\e93f";
}

.inner-tab .nav-tabs li:nth-child(7) .nav-link::before {
  content: "\eb9c";
}

.inner-tab .nav-tabs li:nth-child(8) .nav-link::before {
  content: "\e8bf";
}

.inner-tab .nav-tabs li:nth-child(9) .nav-link::before {
  content: "\eb6a";
}

.inner-tab .nav-tabs li .job_nav.nav-link::before {
  content: "\eb9c" !important;
}

/*======== dashboard css ========== */

.widgetHead {
  /* border-bottom: 1px solid #ddd;
    padding: 15px; */
  justify-content: space-between;
}

.dashHead {
  color: #000;
}

.barHead {
  position: absolute;
  top: 5px;
  left: 10px;
}

.brdBtm {
  border-bottom: 1px solid #ddd;
}

.widgetHead .widget-icon {
  border-radius: 50px;
  margin-right: 8px;
}

.iconHeadTxt {
  color: #000;
}

.widgetCount {
  width: 120px;
  text-align: center;
  padding: 2px 0 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.orderRow .widgetCount:nth-child(even) {
  border-right: 1px solid #ddd;

  margin-right: 2px;
}

.orderRow .widgetCount:last-child:nth-child(even) {
  border: 0;
  margin: 0;
}

.widgetCount:last-child {
  border: 0;
}

.widgetCount .text-muted {
  color: #777 !important;
  font-size: 12px;
}

.widgetCount h5 {
  font-size: 12px;
  font-weight: 600;
}

.side-nav .badge {
  margin-top: 4px;
  float: right;
  background: #9f9f9f;
  color: #fafafa;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: 0;
  justify-content: center;
}
.side-nav .badge:hover {
  background: #ea6666;
  color: #fafafa;
}

.automationDiv {
  text-align: center;
  margin: 30px 0;
}

.autosecond {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.automationDiv h2 {
  color: #727cf5;
}

.tickImg {
  max-width: 150px;
}

.gifImg {
  max-width: 250px;
  margin-top: -20px;
}

.skeletonTableImg {
  width: 100%;
  height: 260px;
  position: relative;
  z-index: 2;
}

.textGrey {
  color: #7f7f7f;
}

.automationDiv p {
  font-size: 18px;
}

.ReactModal__Overlay {
  z-index: 9999 !important;
}

.ril-image-current {
  z-index: 999 !important;
}

.h_200 {
  height: 235px;
}

/* custom notification toast */

.customNotifyToast {
  width: 420px;
  right: 95px;
  transition: all 0.4s ease-in-out;
}

/* .customNotifyToast .Toastify__toast-body {
    padding-left: 50px;
} */

/* .Toastify__toast-container::before {
    content: "";
    background: rgba(0, 0, 0, 0.1);
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
} */

.customNotifyToast .notifyToast {
  padding-left: 50px;
}

.customNotifyToast div {
  transition: all 0.4s ease-in-out;
}

.customNotifyToast .Toastify__toast-icon svg {
  display: none;
}

.customNotifyToast .Toastify__close-button {
  opacity: 1;
}

.customNotifyToast .Toastify__close-button > svg {
  color: red;
  opacity: 1;
}

.bell {
  max-width: 100px;
  position: absolute;
  left: -5px;
  z-index: -1;
}

.email {
  max-width: 150px;
}

.divsecond {
  display: none;
}

.expandToast .divfirst {
  display: none;
}

.expandToast .divsecond {
  display: block;
}

.customNotifyToast .notifyToast.expandToast {
  padding-left: 0px;
  text-align: center;
}

.Toasttxt h4 {
  color: #727cf5;
  margin-bottom: 5px;
}

.Toasttxt p {
  color: #777;
  font-size: 15px;
}

.customNotifyToast .solidBtn {
  background-color: #727cf5;
  border-color: #727cf5;
  color: #fff;
}

.customNotifyToast .solidBtn:hover {
  background-color: #6169d0;
  border-color: #6169d0;
  color: #fff;
}

.customNotifyToast .borderWhiteBtn {
  background-color: transparent;
  border-color: #ddd;
  color: #727cf5;
}

.customNotifyToast .borderWhiteBtn:hover {
  background-color: #727cf5;
  border-color: #727cf5;
  color: #fff;
}

.customNotifyToast .Toastify__progress-bar--success {
  /* display: none;
     */
  opacity: 0 !important;
}

/* =====admin dashboard css starts here==== */

.adminDashboard .card {
  margin-bottom: 10px;
}

.chartHeader {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.teamChartRelative {
  position: relative;
}

.totalSize {
  position: absolute;
  top: 33px;
  left: 2px;
  right: 0px;
  margin: 0 auto;
  width: 100px;
  text-align: center;
}

.totalSize p {
  margin: 0;
}

.plusIcon {
  color: #727cf5;
  font-size: 35px;
  position: relative;
  left: -6px;
  line-height: 1;
}

.teamSize {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.teamSizeRow {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}

.teamCard {
  /* width: 25%; */
  display: flex;
  align-items: center;
}

.progressLine {
  width: 100%;
  height: 7px;
  display: inline-block;
  border-radius: 50px;
  margin-bottom: 5px;
}

.bg_purple {
  background: #727cf5;
}

.bg_yellow {
  background: #ffcf55;
}

.bg_green {
  background: #0ec970;
}

.bg_black {
  background: #0d1432;
}

.bg_red {
  background: #ff5050;
}

.bg_darkgry {
  background: #777777;
}

.w-15 {
  width: 15%;
}

.margin-10 {
  margin-top:10px;
  margin-bottom:10px;
}

.teamCard p {
  margin-bottom: 0;
}

.teamCard h3 {
  color: #000;
  margin-top: 0;
  font-weight: 600;
}

.bulletCircle {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50px;
  margin-right: 0;
  position: relative;
  top: -2px;
}

.chartSelectBox {
  position: absolute;
  right: 4px;
  top: 5px;
  width: 145px;
  padding-left: 5px;
  border: 0;
}

.funnelChart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}

.column_first,
.columnSecond {
  /* width: 40%; */
  text-align: center;
}

.columnSecond {
  width: 17%;
}

.columnThird {
  width: 30%;
}

.funnelChart ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.column_first ul {
  width: 170px;
}

ul.funnel::before {
  content: "";
  width: 15px;
  height: 98%;
  display: block;
  position: absolute;
  left: 9px;
  background: #fff;
  transform: rotate(-15deg);
  top: 15px;
}

ul.funnel::after {
  content: "";
  width: 15px;
  height: 98%;
  display: block;
  position: absolute;
  right: 9px;
  background: #fff;
  transform: rotate(15deg);
  top: 15px;
}

.funnel li {
  padding: 10px;
  color: #fff;
  list-style: none;
  margin: 0 auto;
  height: 40px;
}

.funnel li:first-child {
  height: 60px;
}

.funnelBox1 {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  width: 165px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.funnelBox2 {
  width: 140px;
}

.funnelBox3 {
  width: 120px;
}

/* .funnelBox4 {
    width: 110px;
} */

.bg_600 {
  background: #727cf5;
}

.bg_500 {
  background: #a7aeff;
}

.bg_400 {
  background: #d2d5ff;
}

.bg_300 {
  background: #dddddd;
}

.middleBox {
  font-size: 13px;
  padding: 5px 10px;
  margin-bottom: 9px;
  text-align: center;
  width: 100%;
  color: #fff;
  border-radius: 6px;
}

.columnSecond li {
  list-style: none;
  padding: 10px;
  width: 100%;
  height: 40px;
}

.columnSecond img {
  width: 100%;
}

/* new funnel chart design */

.funnelNew ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.funnelNew li:nth-child(1) {
  width: 100%;
}

.funnelNew li:nth-child(2) {
  width: 85%;
  margin-top: -15px;
  z-index: 1;
}

.funnelNew li:nth-child(3) {
  width: 70%;
  margin-top: -15px;
  z-index: 0;
}

.funnelNew li:nth-child(2) .funnelContent {
  top: 8px;
}

.funnelNew li:nth-child(3) .funnelContent {
  top: 8px;
}

.funnelNew ul li {
  width: 100%;
  height: 80px;
  position: relative;
  margin: 0 auto;
}

.funnelNew ul li img {
  width: 100%;
  height: 100%;
}

.funnelContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.funnelContent h5 {
  text-align: center;
  color: #fff;
  margin: 0;
  font-weight: 400;
}

.funnelValue {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 3px auto 0;
  width: 75%;
}

.funnelValue h6:first-child {
  margin-right: 10px;
}

.funnelValue h6 {
  color: #fff;
  margin: 0;
  font-weight: 400;
  font-size: 15px;
}

.totalOrders {
  border: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
}

.totalOrders h3 {
  margin: 0;
  color: #000;
}

.totalOrders p {
  margin: 0;
}

.worldwide table td {
  color: #000;
}

.worldwide table td:nth-child(2),
.worldwide table th:nth-child(2) {
  text-align: right;
}

.worldwide table td:nth-child(3),
.worldwide table th:nth-child(3) {
  text-align: right;
}

.adminFilters {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.b-0 .css-13cymwt-control,
.b-0 .css-13cymwt-control:hover {
  border: 0 !important;
}

.container_P_0 .container {
  padding: 0;
}

.standard_file_upload button {
  position: relative;
  top: -100px;
}

.cstom_card_outer {
  border: 1px solid #eee;
  padding: 10px;
  box-shadow: 0 0 9px #eee;
  height: 100%;
}

.customFileApprovalUpload .customFormOuter {
  border: 1px solid #ddd;
  margin-top: 5px;
}

.customFileApprovalUpload .card-body form > div {
  width: 24.1%;
  float: left;
  margin-right: 6px;
  height: 85px;
}

.vendor_prfleDp {
  max-width: 100px;
}

/*! Satyam : CSS for customer dashboard donought chart */

/* .prj-dough {
    display: flex;
    padding: 0 0 4rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
} */

.all-charts {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.right_side_chart {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.single-dough {
  text-align: center;
  margin-right: 2rem;
}

.single-dough::before {
  content: "";
  position: absolute;
  top: 5px;
  height: 70%;
  border-right: 1px solid #d2d2d2;
  right: -15px;
}

.single-dough canvas {
  height: 140px;
  width: 140px;
}

.single-dough p {
  font-size: 2rem;
  line-height: 2;
}

.single-dough h6 {
  font-size: 1rem;
  font-weight: 700;
}

.small-font {
  font-size: 11px !important;
  color: #777777;
}

.chartDough {
  text-align: center;
  margin-right: 0.5rem;
}

.chartDough canvas {
  height: 110px;
  width: 110px;
}

.right_side_chart .chartDough:last-child {
  margin-right: 0;
}

.leftside-menu::after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  height: 1px;
  background: #ededed;
}

.percentageFont {
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.2;
}

.transp-btn {
  margin-bottom: 1rem;
}

/* .cust-toast {
    
} */

.choice-btn {
  display: flex;
  justify-content: space-between;
  /* background-color: #0EC970 !important; */
  width: 60% !important;
}

.choice-btn button {
  border: none;
  width: 70px;
  box-shadow: none;
  text-decoration: none;
  /* background-color: #966837; */
}

.choice-btn button:active {
  border: none;
  box-shadow: none;
  text-decoration: none;
  /* background-color: red; */
}

/*! sATYAM cUSTOMER CSS ENDS HERE */

input[type="date"] {
  position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another,*/
input[type=date]:after {
  color: #555;
  content: "\e8aa";
  font-family: unicons;
  padding: 0 5px;
  position: absolute;
  right: 5px;
  top: 3px;
  font-size: 18px;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* ======== vendors signup form css ========== */

.vendorSignupMain {
  background: url(../images/vendorBg.webp);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.Vendorlogo img {
  max-width: 270px;
}

.footer-links2 ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.footer-links2 a {
  color: #fff;
  margin-right: 25px;
  font-size: 16px;
}

.copy-right2 p {
  color: #fff;
}

.vendorLeftSignup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.vendorSignup {
  padding: 30px;
}

.vendorSignupForm {
  background: #fff;
  border-radius: 5px;
  height: 100%;
  padding: 25px;
  width: 100%;
}

.vendorSignupForm .nav.nav-tabs {
  border: 0;
}

.vendorSignupForm .tab-content {
  margin-top: 20px;
}

.vendorSignupForm .nav-tabs .nav-link {
  border: 0;
  color: #000;
  font-size: 17px;
  padding-right: 0;
  margin-right: 20px;
  padding-left: 20px;
  position: relative;
  background: transparent;
}

.vendorSignupForm .nav-tabs .nav-link.active {
  color: #727cf5;
  font-weight: 700;
  /* border-bottom: 2px solid #727cf5; */
}

.vendorSignupForm .nav-tabs li .nav-link::before {
  font-family: "unicons";
  position: absolute;
  left: 0;
}

.vendorSignupForm .nav-tabs li:nth-child(1) .nav-link::before {
  content: "\eb95";
}

.vendorSignupForm .nav-tabs li:nth-child(2) .nav-link::before {
  content: "\ebda";
}

.formNxtBtn {
  background: #4a4a4a;
  border-color: #4a4a4a;
}

/* .vendorSignupForm h2 , .vendorSignupForm p {
    color: #fff;
} */

/* .vendorSignup input[type=text]:active, .vendorSignup input[type=text]:focus , .vendorSignup .form-control {
    background: hsla(0,0%,100%,.1);
    box-shadow: none;
    color: #777;
    border: none;
    outline: none;
}

.vendorSignup .form-label  , .vendorSignup label {
  color: #fff;
} */

.stepCountTop {
  position: absolute;
  right: 45px;
  margin-top: -45px;
}

.stepCountTop span {
  color: #966837;
}

.successVendorMsg h3 {
  color: #966837;
}

.successVendorMsg i {
  font-size: 100px;
  line-height: 1;
  color: #966837;
}

.vendorSignupForm form p {
  margin-bottom: 0;
}

.btn.linkButton {
  position: relative;
  padding: 0;
  line-height: 1;
  background-color: transparent !important;
  border: 0;
  box-shadow: none !important;
  color: #966837 !important;
  font-weight: 700;
}

.btn.linkButton.danger {
  color: red !important;
}

.dflexspcBtwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.gc-map .gmaps {
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
}


.noDataDiv-procurement {
  text-align: center;
  width: 100%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noDataDiv-procurement img {
  max-width: 150px;
}

.noDataDiv-procurement h3 {
  color: #000;
  margin: -20px 0 0;
}

.noDataDiv-procurement p {
  color: #777;
  font-size: 18px;
  margin: 5px 0 10px;
}
.noDataDiv {
  text-align: center;
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noDataDiv img {
  max-width: 200px;
}

.noDataDiv h3 {
  color: #000;
  margin: -20px 0 0;
}

.noDataDiv p {
  color: #777;
  font-size: 18px;
  margin: 5px 0 10px;
}

.no-content-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
}

.no-content-msg img {
  max-width: 350px;
}

.btn_grey {
  border: 1px solid #d9d9d9;
  color: #000;
  box-shadow: 0 0 10px #d9d9d9;
  background: transparent;
}

.btn_grey:hover {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  color: #000;
}

.vendor_chat .col-xxl-9 {
  width: 100%;
}

.elipse {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
}

.width_5 {
  width: 45%;
  max-width: 45%;
}

.width_7 {
  width: 55%;
  max-width: 55%;
}

.text_purple {
  color: #ea6666;
}

.chat-cursor-link {
  cursor: pointer;
  word-break: break-all;
}

.progressLabel ul {
  margin: 0;
  padding: 0;
}

.progressLabel ul li {
  display: inline-block;
  list-style: none;
  margin-right: 10px;
}

.progressLabel ul li span {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 10px;
}

.ViewContainer {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.ViewContainer select {
  width: 150px;
  margin: 10px 0;
}

._2dZTy:nth-child(even) {
  fill: #fff !important;
}

._34SS0:nth-of-type(even) {
  background-color: #fff !important;
}

.action-icon i.sizesm {
  font-size: 16px;
  top: -1px;
  position: relative;
}

.chat-badge {
  position: absolute;
  top: -4px;
  right: -12px;
  background: red;
  color: white;
  font-size: 11px;
  border-radius: 10px;
  padding: 2px 6px;
}

.table .action-icon {
  position: relative;
  line-height: 1;
  height: 20px;
  padding: 0;
  margin-left: 8px;
}

.customerDashChat .col-xxl-9 {
  width: 100%;
}

.customerNofity .table-responsive {
  height: 230px;
}

/* width */
.customerNofity .table-responsive::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.customerNofity .table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.customerNofity .table-responsive::-webkit-scrollbar-thumb {
  background: #727cf5;
  border-radius: 10px;
}

/* Handle on hover */
.customerNofity .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #727cf5;
}

/* .customerDashChat .position-relative.card-body > div {
    height: auto !important;
} */

.customerDashChat .inputChatboxMain {
  padding: 5px !important;
}

.customerDashChat .inputChatboxMain .btn {
  padding: 5px !important;
}

.customerDashChat .chatTypeInput {
  padding: 5px 4px;
  height: 45px;
}

.customerDashChat .chatBoxOuterHght {
  height: 330px;
}

.conversation-list .ctext-wrap {
  background: #f3f3f3;
  border-radius: 8px;
  padding: 5px 10px;
  min-width: 200px;
}

.conversation-list .ctext-wrap:after {
  content: none;
}

.senderName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bg-gry {
  background: #f5f5f5;
  border: 0px !important;
}

.conversation-list .odd .ctext-wrap {
  background-color: #727cf5;
  color: #fff;
}

.chatTypeInput {
  height: 55px;
  border-radius: 10px;
}

.userTypList {
  border-bottom: 1px solid #ddd;
}

.chatSearch input {
  width: 100%;
  position: relative;
  padding-left: 28px;
  margin-bottom: 15px;
  background: #fafafa;
  border-color: #f5f5f5;
}

.chatSearch img {
  position: absolute;
  top: 8px;
  width: 20px;
  left: 17px;
}

.chatFileUpload {
  background: #eee;
  padding: 5px;
  border-radius: 8px;
  margin: 5px 0;
}

.chatFileUpload i {
  font-size: 18px !important;
}

.conversation-list .ctext-wrap i,
.conversation-list .odd .ctext-wrap i {
  color: #333333;
}

.odd .senderName h6.me-2 {
  margin-right: 0 !important;
}

.conversation-text .conversation-text {
  margin-left: 0;
  margin-right: 24px !important;
}

.vendorHoverDetails {
  color: #000;
  margin-bottom: 4px;
  opacity: 1;
  font-family: "Nunito";
}

.vendorHoverDetails span {
  font-weight: 600;
  margin-right: 2px;
}

.vendorHoverBox {
  position: relative;
  padding-right: 50px;
  padding-bottom: 5px;
}

.vendorHoverBox img {
  max-width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  object-fit: contain;
  background: #eee;
  padding: 4px;
  border-radius: 5px;
}

.vendorHoverBox h5 {
  color: #000;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  margin: 5px 0;
  font-family: "Nunito";
}

.vendorHoverBox ol {
  padding-left: 15px;
  margin-bottom: 5px;
}

.vendorHoverBox p {
  font-family: "Nunito";
}

.vendorHoverBox ul li {
  list-style: decimal;
  padding-left: 3px;
  margin-bottom: 1px;
  font-family: "Nunito";
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
}

.cstmVndrBtn {
  background: #727cf5;
  color: #fff;
  box-shadow: none;
  border: 0;
  padding: 5px 8px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.gm-style .gm-style-iw-c {
  box-shadow: none;
  max-height: 170px !important;
}

.gm-style-iw-d {
  max-height: 170px !important;
}

.notifyeclipse {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 230px;
  white-space: nowrap;
  margin-bottom: 2px !important;
}

.customerDshbrdHeading .card-body {
  padding: 0;
}

.customerDshbrdHeading .card {
  box-shadow: none;
  margin: 0;
  height: 150px;
  overflow: auto;
}

.vendorDashbd.customerDshbrdHeading .card {
  height: 210px;
}

.vendorfilesDashbd.customerDshbrdHeading .card {
  height: 345px;
}

.vendor_chat .chatBoxOuterHght {
  height: 370px;
}

.customerDshbrdHeading .customPagination {
  display: none;
}

.fntsmall {
  font-size: 13px;
}

.toggleSwitch {
  position: absolute;
  top: 10px;
  right: 15px;
}

.search_bar {
  position: relative;
}

.search_bar input {
  padding-left: 30px;
  background: #f5f5f5;
  border: 0;
  font-size: 14px;
  color: #8e8e8e;
  height: 35px;
  text-transform: capitalize;
}

.search_bar input:focus {
  background: #f5f5f5;
}

.search_bar i {
  position: absolute;
  left: 7px;
  top: 3px;
  font-size: 22px;
  color: #d2d2d2;
}

/* custom-tree.css */

#treeWrapper {
  width: 100%;
  min-height: 80vh;
  height: 80vh !important;
}

/* #treeWrapper svg > g {
   
} */

.node__root > circle {
  fill: #966837;
  /* r:8px; */
  transform: scale(0.5);
}

.node__branch > circle {
  fill: #727cf5;
  transform: scale(0.5);
}

.node__leaf > circle {
  fill: #1e9207;
  transform: scale(0.5);
}

.rd3t-label__title {
  fill: #000 !important;
  stroke: none;
  font-size: 10px;
  font-weight: 500;
  stroke: white !important;
  stroke-width: 0.5em;
  paint-order: stroke;
  stroke-linejoin: round;
  transform: translate(-50px, 25px);
}

.node__root, .node__branch, .node__leaf {
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cheque_image {
  height: 100px;
  object-fit: contain;
  width: 150px;
}

.cursor-pointer {
  cursor: pointer;
  color: #727cf5;
}

.gant-custom-outer svg text {
  fill: #000 !important;
}

.cheque-img {
  height: 100px;
  object-fit: contain;
  width: 150px;
}

.cursor-pointer-option {
  cursor: pointer;
}

.iot-iframe {
  width: 100%;
  height: 100vh;
}

/* google map */
.map-backbutton {
  z-index: 9999;
  position: absolute;
  left: 10px;
  top: 60px;
  background: #fff;
  box-shadow: 0 0 15px #ddd;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

.mr-2 {
  margin-right: 20px;
}

.gant_header {
  border-top: 1px solid #eee;
}

.gant_header,
.gant_tr_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #eee;
}

.gant_td {
  height: 50px;
  border-left: 1px solid #eee;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 90px;
}

.project_name_td {
  width: 190px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  padding-top: 15px;
}

.profile_img {
  max-width: 100px;
  /* margin-bottom: 15px; */
}

.map_search {
  position: absolute;
  top: 10px;
  left: 190px;
  padding: 4px;
  border: 0;
  height: 40px;
  width: 300px;
}

.map_outer {
  width: 100%;
  height: 410px;
}

.form-section {
  border: 1px solid #eee;
  padding: 10px;
  box-shadow: 0 0 9px #eee;
  /* width: 100%; */
  background-color: #fcfcfc;
  border-radius: 8px;
}

.section-title {
  text-align: center;
  color: #1e1e1e;
  font-size: 20px;
  margin: 10px 0 20px;
}

.form-section-title {
  width: fit-content;
}

h3.form-section-title:focus {
  outline: none;
}

.form-section div .form-check:last-child {
  margin-bottom: 15px;
}

.cstm_frm_btns {
  display: inline-block;
  width: 100%;
  text-align: left;
}

.customFormOuter .manage_form_outer label:empty {
  margin: 0;
  display: none;
}

.fileUpload_footer {
  justify-content: flex-start;
}

.his_user_nme {
  display: inline-block;
  font-weight: bold;
  color: #000;
  margin: 0 0 5px;
}

.aprv_status_txt,
.cment_date {
  font-size: 14px;
  color: #494949;
}

.cmmt_histry {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.aprval_stats_timeline_nav li button,
.aprval_stats_timeline_nav li button:hover {
  border: 0 !important;
  color: #000;
  padding-left: 0;
  padding-right: 0;
  margin-right: 20px;
}

.aprval_stats_timeline_nav li:last-child button {
  margin-right: 0px;
}

.aprval_stats_timeline_nav li button.active {
  border-left: 0;
  border-top: 0;
  border-right: 0;
  color: #727cf5 !important;
  border-bottom: 1px solid #727cf5 !important;
}

.active_search .conversation-text .ctext-wrap {
  background-color: #4e57bc !important;
  color: #fff;
}

.active_search .innerMessage {
  background-color: #4e57bc !important;
  color: #fff !important;
}

.active_search .innerMessage .messageTxt {
  color: #fff !important;
}

/* temporary class to disable typeahead */
.custom_not_allowed input {
  pointer-events: none;
  background-color: #eee;
  color: grey;
}

.arrow-up-down-update-green {
  color: white;
  background: #0ec970;
  width: fit-content;
  border-radius: 100%;
}

.arrow-up-down-update-span-green {
  color: #0ec970;
  margin-left: 5px;
}

.arrow-up-down-update-red {
  color: white;
  background: #ff5050;
  width: fit-content;
  border-radius: 100%;
}

.arrow-up-down-update-span-red {
  color: #ff5050;
  margin-left: 5px;
}

.right_foot_text {
  position: relative;
  bottom: 5px;
  left: 3px;
}

.custom-popover-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.transaction-list-modal .modal-dialog {
  max-width: 700px;
}

/* ===================Style To Put Action Button Allign with Tab Header In Project and Enquiry=================== */

.inner-tab .innerbuttonOuter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 27%;
  margin-top: 8px;
}

/* .enqry_innr_tb.inner-tab .innerbuttonOuter {
    width: 60%;
}

.enqry_innr_tb.inner-tab .nav.nav-tabs {
    width: 40%;
} */

/* ===================Style To Put Action Button Allign with Tab Header In Project and Enquiry=================== */

.customTitleHead.dflexspcBtwn:empty,
.customTitleHead.dflexspcBtwn .d-flex:empty {
  display: none !important;
}

.items_end {
  justify-content: flex-end;
}

/* .customFormOuter .form-check-input.form-control {
    padding: 0;
} */

.form_label_info h4 {
  color: #000;
  font-size: 17px;
  margin-bottom: 0;
}

.form_label_info p {
  font-size: 14px;
}

/*===================================responsive start here================================================*/

/* For 4K Displays (Ultra HD) */
@media screen and (min-width: 3840px) {
}

/* For Projectors or Higher Resolution Screens (Full HD) */
@media screen and (min-width: 1920px) {
}

/*============================= Tablet landscape size to standard 960  =============================*/
@media only screen and (min-width: 959px) and (max-width: 1024px) {
}

/* ============================= global tablet and mobile =============================*/
@media only screen and (max-width: 959px) {
  .vendorLeftSignup {
    padding: 20px;
    text-align: center;
    justify-content: flex-start;
    height: 100%;
    min-height: 100%;
  }

  .vendorLeftSignup .footer-links2 {
    display: none;
  }

  .vendorSignup {
    padding: 10px;
  }

  .vendorSignupForm {
    padding: 15px;
  }

  .stepCountTop {
    margin-top: -25px;
  }

  .login_logo {
    top: 20px;
    left: 15px;
  }

  .login_logo img {
    max-width: 225px;
    object-fit: contain;
  }

  body.authentication-bg .account-pages {
    padding-top: 135px !important;
  }

  .inner-tab .nav.nav-tabs {
    position: relative;
  }

  .inner-tab .innerbuttonOuter,
  .inner-tab .nav.nav-tabs {
    width: 100%;
    justify-content: flex-start;
  }

  .inner-tab .tab-content {
    margin-top: 10px;
    width: 100%;
  }
}

/* ================Tablet Portrait size to standard 960 (devices and browsers) ================*/
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .side-nav .side-nav-link {
    padding: 5px;
  }

  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
    .side-nav
    .side-nav-item
    .side-nav-link {
    padding: 5px 20px;
    min-height: auto;
  }

  body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
    .side-nav
    .side-nav-item:hover
    > ul {
    top: 21px;
  }

  .customTitleHead .btn {
    margin-bottom: 5px;
    padding: 5px;
  }

  .removeicon .side-nav-second-level {
    margin-top: 12px !important;
  }
}

/* ==================All Mobile Sizes (devices and browser) ==================*/

@media only screen and (max-width: 767px) {
  .card-body {
    flex: 1 1 auto;
    padding: 0.5rem 0.5rem;
  }

  .leftside-menu,
  body.sidebar-enable .side-nav .menuitem-active a.side-nav-link,
  body.sidebar-enable .side-nav a.side-nav-link:hover,
  body.sidebar-enable .side-nav a.side-nav-link {
    width: 190px !important;
  }

  .side-nav .side-nav-link {
    padding: 5px;
  }

  .side-nav-second-level li a {
    padding: 3px 10px 3px 20px;
  }

  body.sidebar-enable .navbar-custom {
    left: 190px !important;
  }

  .logo.text-center {
    text-align: left !important;
    padding-left: 10px;
  }

  .logo .logo-lg img {
    height: 45px !important;
  }

  .adminDashboard .d-flex.justify-content-start {
    flex-wrap: wrap;
  }

  .widgetCount {
    width: 115px;
  }

  .width_7,
  .width_5 {
    width: 100%;
    max-width: 100%;
  }

  .customTitleHead {
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .customTitleHead .btn {
    margin-bottom: 5px;
    padding: 5px;
  }

  .customNotifyToast {
    width: 350px;
    right: 0;
    transition: all 0.4s ease-in-out;
  }

  .gant-custom-outer {
    overflow-x: scroll;
  }

  .col-mb-100 {
    width: 100%;
  }

  .cstom_card_outer {
    overflow: auto;
  }

  .mb_mr_2 {
    margin-bottom: 10px;
  }

  .search_bar input {
    width: 120px;
    padding-right: 4px;
  }

  .customFileApprovalUpload .card-body form > div {
    width: 100%;
  }

  .px-3.pb-1.inputChatboxMain {
    padding: 0 !important;
  }

  .mb_w_100 {
    width: 100%;
  }

  .mb_dsply_blck {
    display: block;
  }

  .file-container {
    display: block !important;
  }

  body[data-leftbar-theme="dark"] .leftside-menu .logo {
    background: transparent !important;
  }

}

/* ===================Mobile Landscape Size to Tablet Portrait (devices and browsers)=================== */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* =====================Mobile Portrait Size to Mobile Landscape Size (devices and browsers)===================== */

@media screen and (max-width: 479px) {
  ul.pagination {
    display: none !important;
  }

  .orderRow .widgetCount:nth-child(2n) {
    border: 0;
  }

  .widgetCount {
    width: 49%;
  }
}

.customPosition {
  position: sticky;
  left: 0;
  top: 0;
}

.tableOuter {
  padding: 10px 10px 0;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
}
 
.customTable {
  /* table-layout: fixed;
  width: 100%; */
  background: #fff;
  padding: 15px !important;
  vertical-align: middle;
  margin-bottom: 0;
}

.customTable .form-floating > .form-control,
.form-floating > .form-select {
  height: 2.5rem;
  padding: 0 10px !important;
  font-size: 13px;
  font-weight: 500;
  font-family: var(--primary-font-family);
}

.customTable .form-floating > label {
  padding: 0.6rem;
  font-weight: 500;
  color: var(--dark-grey);
  font-family: var(--primary-font-family);
}

.customTable .form-floating input {
  background: #f5f5f5;
  border: 0;
}

.customTable .form-control:focus {
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.8) translateY(-0.5rem) translateX(0.15rem);
  color: var(--green-color);
}

.customTable tr td {
  padding: 5px;
  font-weight: 400;
  vertical-align: middle;
  font-size: 13px;
}

/* .customTable tr:last-child td {
  border: 0;
} */

.customInputWidth {
  width: 115px !important;
}

.customTable tr th {
  vertical-align: middle;
  font-weight: 500;
  color: #323232;
}

.customDflex {
  display: flex;
  flex-direction: row;
}

/* range datepicker css */

.customRangeDatePicker {
  position: fixed;
  top: 250px;
  right: 50px;
  width: auto;
  z-index: 2;
  border: 1px solid #ddd;
  box-shadow: 0 0 9px #ddd;
}

.customRangeDatePicker .rdrInputRangeInput {
  width: 40px;
}

.pvtVertList {
  vertical-align: top !important;
}

.map-margin {
  margin: 5px;
}

.lead-locate-view {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  padding: 2% 2%;
  align-items: flex-start;
}

.lead-locate-view div {
  width: 75%;
}
.lead-locate-view div .details-div {
  width: calc(100vw- 260px);
}
.lead-locate-view div div .backbtn {
  width: fit-content;
  height: 12%;
}

.lead-locate-view div p {
  font-weight: bolder;
}

.lead-locate-view div p span {
  font-weight: bold;
}

.sequence-label {
  color: black;
  font-weight: 600;
}

.sequence-input {
  width: 50px;
  margin-left: 5px;
}

/* btn customization */
.button-outline-dark {
  /* position: relative; */
}

.button-outline-dark .smIcon {
  fill: currentColor;
  transition: fill 0.3s ease;
}

.button-outline-dark:hover .smIcon {
  fill: white !important; 
}
.indent-item-card {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.indent-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.indent-item-id {
  font-weight: bold;
  color: #333;
}

.indent-item-text {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.indent-item-text-bottom {
  font-size: 0.9rem;
  color: #555;
  padding-bottom: 0.5rem;
}

.indent-item-top {
  margin-left: auto;
}

.indent-item-button {
  margin-left: auto;
  align-self: center;
}

.view-download-btn{
  display: flex;
  flex-direction: column;
  gap:2px
}

.file-container {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.file-image {
  max-width: 50px;
  max-height: 50px;
  cursor: pointer;
}

.download-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; 
}

.file-container:hover .download-icon {
  opacity: 1;
  pointer-events: auto; 
}
.file-container:hover .file-image {
  opacity: 0.5;
}

.view-download-btn{
  display: flex;
  flex-direction: column;
  gap:2px
}
/* delete button icon customization */

.mdi-delete{
  color: #ea6666 !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

.kt-radio--solid {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.kt-radio--solid input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.kt-radio--solid:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.kt-radio--solid input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.kt-radio--solid input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.kt-radio--solid .checkmark:after {
 	top: 4px;
	left: 4px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}


/* new css changes 12-sep-2024 by ravi */

body {
  background: #F5F5F5 !important;
}

.outer_wrapper {
  background: #fff;
  padding: 15px;
  border-radius: 16px;
}

.title_right {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  width: 60%;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.search_outer {
  position: relative;
}

input.search_bar {
  position: relative;
  height: 35px;
  background: #F5F5F5;
  border: 0;
  font-size: 14px;
  text-transform: capitalize;
  color: #8E8E8E;
  max-width: 250px;
  padding-left: 35px;
}

input.search_bar:focus {
  background: #F5F5F5;
}

span.search_icon {
  left: 10px;
  position: absolute;
  top: 5px;
  font-size: 17px;
  color: #8E8E8E;
}

.btn.btn-outline-dark, .customFormOuter .btn.btn-primary {
  color: #2A2A2A;
  border-color: #2A2A2A;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.btn.btn-dark {
  color: #fff;
  border: 0;
  background-color: #2A2A2A;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
}

.btn.btn-dark:hover {
  background-color: #000;
}

.btn.btn-outline-dark:hover, .customFormOuter .btn.btn-primary:hover{
  color: #fff;
  background: #2A2A2A;
}

.btn.backbtn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.cancel_btn , .customFormOuter .btn.btn_grey{
  background: #ffeff2;
  color: #fa5c7c;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #fa5c7c;
  box-shadow: none;
}

.btn.cancel_btn:hover, .customFormOuter .btn.btn_grey:hover {
  color:#fff;
  background:#fa5c7c;
}


.side-nav-item a.has-arrow.side-sub-nav-link.side-nav-link.menuitem-active {
  background: #f5f5f5;
  color: #101010 !important;
}

body[data-leftbar-theme=dark] .side-nav-item.menuitem-active > a , body[data-leftbar-theme=dark] .side-nav-item.menuitem-active a.active, body[data-leftbar-theme=dark] .side-nav-item.menuitem-active a.active:focus {
  background-color: #2A2A2A;
  color: #fff;
}

body[data-leftbar-compact-mode=condensed]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse, body[data-leftbar-compact-mode=condensed]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing {
  top: 30px;
}

.customPagination .paginate_button i {
  font-size: 20px;
  line-height: 1;
}

.approve_btn {
  display: flex;
  background: rgba(14, 201, 112, 0.1);
  color: #22A06B;
  border: 0;
  border-radius: 4px;
  padding: 2px 12px;
  margin: 4px;
  font-weight: 500;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-width: 100px;
}

.reject_btn {
  background: #FFEDEB;
  color: #E34935;
  display: flex;
  border: 0;
  border-radius: 4px;
  padding: 2px 12px;
  margin: 4px;
  font-weight: 500;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-width: 100px;
}

.custom_nav_tabs {
  background: #F5F5F5;
  border: 0;
  border-radius: 8px 8px 0px 0;
}

.custom_nav_tabs .nav-link {
  color: #222222;
  font-size: 14px;
}

.custom_nav_tabs .nav-link.active {
  background: #222222 !important;
  color: #fff;
  border-radius: 8px 8px 0px 0;
}

.customFormOuter h4{
  color: #1e1e1e;
}

.customFormOuter hr {
  margin-bottom: 10px;
  height: 0;
}

.btn_left {
  float: left;
  margin-right: 10px;
}

.radio_cstm .form-check {
  display: flex;
  padding: 0;
  gap: 5px;
  align-items: center;
  float: left;
  margin-right: 15px;
}

.radio_cstm .form-check:last-child {
  margin-bottom: 0 !important;
}

.customFormOuter .error-message {
  top: -15px;
}

.customTitleHead.dflexspcBtwn.card {
  box-shadow: none;
}

.outer_wrapper.container-fluid .col-lg-12 > .card {
  box-shadow: none;
}

.custom_checkbox > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.custom_checkbox .form-check {
  margin: 0;
  min-width: 100px;
  max-width: 100px;
}

.custom_checkbox .form-check input[type="checkbox"] {
  margin-bottom: 0;
}

.customTitleHead.dflexspcBtwn {
  padding-left: 8px;
}

.customFormOuter {
  padding: 0 8px;
}

.modal-header h4, .modal-header .h4 {
  margin: 0;
  color: #1e1e1e;
  font-size: 20px;
}

.custom_modal div:empty, .custom_modal .section-title:empty , .section-title:empty {
  display: none !important;
}

.custom_modal .modal-body form div {
  margin-bottom: 10px;
}

.custom_modal .modal-body form div label {
  font-weight: 600;
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #2A2A2A;
}

.custom_modal .modal-body .error-message {
  top: -10px;
}

.custom_modal .error-message-wrapper {
  margin-bottom: 0 !important;
}

.indent_id p {
  font-size: 14px;
  margin-bottom: 2px;
}

.view-download-btn i {
  font-size: 14px;
  line-height: 1.2;
}

.view-download-btn .btn {
  height: auto;
}

/* run mrp page css changes */

.run_mrp_card {
  box-shadow: 0 0 9px #eee;
  margin: 10px 0;
  padding: 8px;
  border-radius: 10px;
}
.run_mrp_card .run_mrp_card {
  padding: 0px;
  margin: 6px 25px 0px;
  box-shadow: none;
}
.run_mrp_card span.float-left {
  font-weight: 400;
  color: #2A2A2A;
  font-size: 14px;
  margin-left: 25px;
  cursor: inherit;
}

.run_mrp_card span.float-left strong {
font-weight: 700;
}

.indent-item-top {
  font-weight: 400 !important;
}

.run_mrp_card span.float-left::after {
  content: "";
  background: #EA6666;
  width: 2px;
  height: 15px;
  display: inline-block;
  position: relative;
  left: 12px;
  top: 3px;
}

.run_mrp_card span.float-left:last-child::after {
  content: none;
}

.indent-item-top.btn.btn-success {
  font-size: 14px;
  border-radius: 4px;
  height: 35px;
  background-color: #0f9f76;
  border-color: #0f9f76;
}

.bom_table .table thead tr th:first-child {
  width: 160px !important;
  display: block;
}

.run_mrp_card .float-left:nth-child(2){
  margin-left: 15px !important;
}

.run_mrp_outer {
  padding:0 15px;
}

button.indent-item-top {
  min-width: 95px;
  padding: 4px;
  height: auto !important;
  font-size: 13px !important;
}

button.indent-item-top.btn-success:disabled {
  opacity: 0.3;
}
button.indent-item-top.btn-outline-dark:disabled {
  background: #ddd !important;
  cursor: not-allowed !important;
  border-color: #ddd !important;
  color: #777;
  /* opacity: 0.5; */
}




/* history and timeline page new */

.history_section {
  border-left: 1px solid #d2d2d2;
}

.approval_basic_details {
  margin-top: 10px;
}

.approval_row {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  gap: 15px;
}

.aprvl_crd {
  display: flex;
  align-items: center;
  gap: 24px;
}

.approval_box div h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

.approval_box div p {
  margin-bottom: 2px;
  font-size: 14px;
  color: #4f4f4f;
}

.approval_box div h4 {
  font-size: 14px;
  font-weight: 600 !important;
  margin: 0;
  color: #2B2B2B;
}

.entity_head {
  margin-bottom: 18px;
}

.approval_Card {
  background: #FCFCFC;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.approval_row .approval_Card.w-40 {
  width: 40%;
}

.approval_row .approval_Card.w-60 {
  width: 60%;
}

.inner_section_title {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 24px;
  padding-bottom: 18px;
}

.approval_box {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.approval_Card span {
  color: #353F51;
  font-size: 14px;
}

.approval_Card p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Figtree';
  color: #4F4F4F;
  margin-bottom: 10px;
  font-size: 14px;
}

.approval_Card p span img {
  max-width: 17px;
  margin-right: 8px;
  position: relative;
  top: -2px;
}

.type_details h4 {
  color: #222222;
  margin: 2px 0;
}

.approval_Card p span {
  margin: 0;
}

.reassign img {
  max-width: 14px;
  line-height: 1;
  position: relative;
  top: -2px;
  left: -3px;
}

.reassign.disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
  opacity: 0.3;
}


.approve_solid_btn {
  border: 0;
  background: #22A06B;
  color: #fff;
  font-size: 12px;
  padding: .65rem 16px;
  border-radius: 4px;
}

.approve_solid_btn img , .reject_solid_btn img{
  max-width: 13px;
  margin-right: 3px;
}

.reject_solid_btn {
  border: 0;
  background: #E34935;
  color: #fff;
  font-size: 12px;
  padding: .65rem 16px;
  border-radius: 4px;
}

.approve_solid_btn.disabled,  .reject_solid_btn.disabled {
  background-color: #DCDFE4;
  color: #A7AFBC;
  cursor: not-allowed !important;
}

.approve_solid_btn.disabled img, .reject_solid_btn.disabled img {
  opacity: 0.5;
}

.footer_timeline_head {
  position: relative;
}

.history_heading {
  background: #F4F5F7;
  color: #505F79;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  position: absolute;
  left: 27%;
  margin-top: 3px;
}


.approval_profile {
  display: flex;
  align-items: center;
}

.approval_profile h4 {
  margin: 0;
  font-size: 14px;
  color: #2B2B2B;
}

.approval_profile p {
  color: #4F4F4F;
  margin: 0;
}

.approval_profile img {
  max-width: 26px;
  margin-right: 10px;
  border-radius: 50px;
}

.approval_profile img.approval_status {
  max-width: 28px;
  margin-right: 10px;
}

.approval_timeline_info img.approval_status {
  max-width: 26px;
}

.approval_timeline_info h5 {
  font-size: 14px;
  color: #2B2B2B;
}

.approval_timeline_info h5 span {
  font-weight: 400 !important;
  color: #6E6E6E;
}

.approval_timeline_info  p {
  color: #4F4F4F;
  margin: 0;
  font-size: 13px;
}

.approval_timeline_info .date_time {
  color: #969696;
  font-size: 12px;
}

.approval_history::before {
  content: "";
  width: 2px;
  height: 105%;
  display: inline-block;
  position: absolute;
  left: 12px;
  top: 15px;
}

.approval_timeline::before {
  background: #22A06B;
}

.reassigned_timeline::before {
  background: #2196F3;
}

.rejected_timeline::before {
  background: #E34935;
}

.approval_history {
  padding-left: 40px;
  position: relative;
}

.approval_status {
  position: absolute;
  left: 0;
}

.approval_timeline_info, .approval_profile {
  margin-bottom: 20px;
}

.text_red {
  color: #FF5959 !important;
}

.date_time img {
  max-width: 15px;
}

.smalldown_arrow {
  max-height: 20px;
  margin: 3px 0;
}

p.due_date {
  color: #4F4F4F;
}

.timeline_outer {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  padding-top: 5px;
}

.timeline_outer .history_inner_outer {
  margin: 10px 0 18px;
  height: 500px;
  overflow: auto;
}

.after_rejected_timeline::before {
  background: #DCDFE4;
}

.after_rejected_timeline {
  opacity: 0.3;
}

.after_rejected_timeline .approval_profile {
  margin-bottom: 50px;
}

.after_rejected_timeline .approval_profile:last-child{
  margin-bottom: 20px;
}

.cstm_bdge {
  padding: 2px 5px;
  border-radius: 5px;
  display: block;
  width: 125px;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}

.approved {
  background-color: #EEFDF7;
  color: #164B35;
}

.re-assigned {
  background-color: rgba(25, 118, 210, 0.15);
  color: #1976D2;
}

.rejected {
  background-color: #FFEDEB;
  color: #601E16;
}

.assigned {
  background-color: rgba(255, 199, 85, 0.1);
  color: #F57C00;
}

.d-flex_spc_btween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.icon_outer {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50px;
  background: rgba(33, 150, 243, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon_outer img {
  max-width: 20px;
}

.payment_info {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.payment_form {
  width: 50%;
}

.light_red {
  background-color: rgba(255, 89, 89, 0.1);
}

.light_grey {
  background-color: rgba(167, 175, 188, 0.1);
}

.light_blue {
  background-color: rgba(33, 150, 243, 0.1);
}

.light_green {
  background-color: rgba(238, 253, 247, 1);
}

.light_orange {
  background-color: rgba(255, 152, 0, 0.1);
}

.flx-end {
  display: flex;
  justify-content: flex-end;
}

.approval_Card .head_title, .head_title {
  font-weight: 600;
  font-size: 16px;
  color: #353F51;
}

/* payment list redesinged */

.milestone_row {
  display: inline-flex;
  align-items: center;
  gap: 25px;
  position: relative;
}

.milestone_row p {
  margin: 0;
  font-size: 15px;
  color: #222222;
  position: relative;
}


.milestone_row p:after {
  background: #ea6666;
  content: "";
  display: inline-block;
  height: 16px;
  left: 12px;
  position: relative;
  top: 3px;
  width: 2px;
}


.invoice_payment_row .milestone_row {
  margin-top: 5px;
  margin-bottom: 7px;
}

.invoice_payment_row p {
  font-size: 14px;
}

.teal_btn {
  background-color: #008080 !important;
  border-color: #008080 !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 13px;
}

.invoice_payment_outer {
  padding-left: 30px;
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 10px;
}

.invoice_payment_outer i.bullet {
  font-size: 16px;
  position: absolute;
  left: -22px;
  top: 0px;
  color: #008080;
  cursor: pointer;
}

.btn.add_icon {
  padding: 6px 10px;
  border-radius: 7px;
  line-height: 1;
}

.milestone_bigger {
  width: 100%;
  padding: 5px 0;
}

.milestone_bigger p {
  font-size: 17px;
}

.milestone_bigger .teal_btn {
  position: absolute;
  right: 0;
}

.milestone_row p:last-child::after, .cnt_none::after {
  content: none !important; 
}

/* custom checklist in job details */

.cstm_chcklst .checklist-wrapper {
  width: 100%;
}

.cstm_chcklst .item-action-wrapper {
  margin-left: 0;
}

.cstm_chcklst .item-action-wrapper input {
  border: 1px solid #dee2e6;
  padding: 0.45rem 0.9rem;
  width: 100%;
  border-radius: 0.25rem;
}

.cstm_chcklst .check-list-input-wrapper .close {
  padding: 0;
  color: red;
  background: transparent !important;
}
.cstm_chcklst .check-list-input-wrapper {
  padding-left: 0;
  align-items: center;
}

.cstm_chcklst .item-action-btn-wrapper, .cstm_chcklst .item-action-wrapper {
  margin-bottom: 10px;
}

/* select job process modal redesigned*/

.process_modal .modal-dialog {
  max-width: 75%;
}

.process_box {
  border: 1px solid #8E8E8E;
  border-radius: 10px;
  overflow: auto;
  height: 100%;
  max-height: 137px;
}

.process_row {
  display: inline-flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  width: 100%;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 10px;
}

.process_row span {
  color: #2A2A2A;
  font-size: 14px;
}

.process_row.process_head {
  border: 0;
  padding-bottom: 0;
}

.process_head span {
  color: #8E8E8E;
  font-weight: bold;
  font-size: 15px;
}

.process_box h4 {
  background: #F5F5F5;
  margin: 0;
  padding: 8px 10px;
  border-radius: 10px;
  font-size: 15px;
}

.process_checkbox h5 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.w-60px {
  width: 60px;
}

/* bom item category redesigned */

.bom_item_category {
  padding:0 10px;
}

.catgry-head {
  border-bottom: 1px solid #ddd;
  padding: 0;
}

.catgry-head h5 {
  color: #181818;
}

button.btn_icon, button.btn_icon:hover,button.btn_icon:focus {
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-size: 20px;
}

button.btn_icon:disabled {
  background: transparent;
}

.btn_icon i.uil-trash {
  color: #EA6666;
  margin-right: 10px;
}

.btn_icon i.uil-check {
  color: #000000;
}

.basic-multi-select .select__multi-value {
  background: rgba(0, 128, 128, 0.1);
  border-radius: 17px;
  padding: 4px;
}

.basic-multi-select .select__multi-value__label {
  color: #008080;
  font-size: 11px;
}

.basic-multi-select .select__multi-value__remove:hover {
background: transparent;
}

.inventry_table   td{
  vertical-align: middle;
}

.inventry_fields label {
  color: #1e1e1e;
  font-size: .9rem;
  font-weight: 600;
  margin-bottom: 5px;
  width: 22%;
  text-align: right;
  padding-right: 10px;
}

.projct_name_txt {
  color: #1e1e1e;
  font-size: 14px;
  margin-bottom: 5px;
}

/* hierachy */

.indication_badge {
  width: 20%;
}
.indication_badge label {
  display: block;
  margin-bottom: 7px;
  color: #000000;
  font-size: 14px;
}

.indication_badge label span {
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50px;
  margin-right: 3px;
  position: relative;
  top: 2px;
}

.indication_badge label span.brown {
background: #966837;
}

.indication_badge label span.voilet {
  background: #727CF5;
}

.indication_badge label span.green {
  background: #1E9207;
}

.edit_icon_btn {
  font-size: 18px !important;
  color: #2A2A2A !important;
  cursor: pointer;
  border: 0;
  height: auto !important;
}

.customFormOuter section.form-section.row {
  margin: 0;
}

.rfq_indent_table .table thead tr th:nth-child(2){
  width: 30%;
}

.rfq_indent_table .table thead tr th:nth-child(3){
  width: 20%;
  text-align: right;
}

/* .rfq_indent_table .table thead tr th:nth-child(4){
  width: 20%;
} */

span.quantity_uom {
  min-width: 70px;
  display: inline-block;
  text-align: right;
}

.border_table td, .border_table th {
  border: 1px solid #000;
}

.slct_itm_rfq_tbl .table tbody tr td {
  width: calc(100%/4);
}

.slct_itm_rfq_tbl .table tbody tr td:first-child {
  width: 2%;
}

/* .slct_itm_rfq_tbl .table thead th:nth-child(3) {
  padding-left: 50px;
} */

.slct_itm_rfq_tbl .table tr:last-child td {
  border: 0;
}

/* head sticky */

.stcky_head {
  position: sticky;
}

.iner_tbl_outr {
  overflow: auto;
  height: 80vh;
  padding-right: 5px;
}

/* width */
.iner_tbl_outr::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.iner_tbl_outr::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #777777;
  border-radius: 10px;
}

/* Handle */
.iner_tbl_outr::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px #222;
  border-radius: 10px;
}

.edit_icon_btn.p-0.btn.btn-primary:hover {
  background: transparent;
}

.scnd_thd_ryt thead tr th:last-child {
  text-align: right;
}