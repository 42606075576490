.new_fileupload {
    /* background-color: red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 9rem;
    padding: 0 0 1rem 0;
    border: 2px dashed #D2D2D2;
}

.file-container {
    /* background-color: aqua; */
    display: flex;
    /* padding-top: 1rem; */
    align-items: center;
    justify-content: center;
}

.image-container {
    margin: 0 2rem 0 2rem !important;
    height: 6.5rem;
    width: 8rem;

}

.view-btn {
    margin-right: 0.5rem;
    background-color: #727CF5;
    border: none;
}

.view-btn:hover {
    background-color: #606cf3;
}

.view-btn:focus {
    background-color: #606cf3;
    box-shadow: none;
}

.remove-btn {
    background: none !important;
    box-shadow: none;
    border: none;
    color: #FF5050 !important;
    font-weight: 600;

}

.drop-file {
    /* background-color: #5000fc; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 1rem;
}

.drop-file h5 {
    /* background-color: blueviolet; */
    line-height: 0.7;
    opacity: 70%;
}

.drop-file h6 {
    opacity: 50%;
}

.drop-file img {
    margin-top: 1.5rem;
}



.test {
    background-color: #ffffff;
    color: #1e1e1e;
    border: none;
    box-shadow: none;
    font-size: 1rem;
    font-weight: 600;
    border: 1px solid #1e1e1e;
}

.btn-container {
    display: flex;
    height: 37px !important;
    width: 100% !important;
    /* background-color: #FF5050 !important; */
}


/*! lOADING SCREEN */

.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    flex-direction: column;
    /* background-color: #FF5050; */

}

.loading-screen img {
    height: 100px;
    width: 100px;
}


/*? preview selected image */

.file-name {
    padding-left: 2rem;
    font-size: 0.8rem !important;
    /* background-color: #FF5050 !important; */
    margin-bottom: -2rem;
}



.vlogo-container {
    /* background-color: #FF5050; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;

}

.vndr-view-btn {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    background-color: #727CF5;
    border: none;


}

.vndr-view-btn:hover {
    background-color: #606cf3;
    box-shadow: none;
}

.vndr-view-btn:focus {
    background-color: #606cf3;
}

.vndr-remove-btn {
    background: none !important;
    box-shadow: none;
    border: none;
    color: #4A4A4A !important;
    font-weight: 600;
}

.active-remove-btn {
    background: none !important;
    box-shadow: none !important;
    border: none;
    color: #FF5050 !important;
    font-weight: 600;
}

.active-remove-btn:active {
    box-shadow: none;
    border: none;
}


/*! crop image option css */

.back-drop {
    position: fixed;
    background-color: #4A4A4A;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}

.crop-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    /* background-color: #FF5050; */
    height: 600px !important;
    width: 500px !important;
}

.crop-btn-container {
    /* background-color: #f7ff02; */
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    align-items: flex-end;
    flex-direction: row;
    gap: 2rem;
    margin-top: -3rem;
}

.crop-btn {

    z-index: 99999;
    background-color: #606cf3;
}

.reactEasyCrop_Container {
    height: 70vh !important;
    width: 100% !important;
}

.crop-container-hide {
    display: none;
}

.loading-screen-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    /* background-color: #FF5050; */
    z-index: 999;
    position: absolute;

}

.loading-screen-custom img {
    height: 200px;
    width: 200px;
}


/* enquiry_container--------- */

.enquiry_container {
    /* background-color: #2dd246 !important; */
    display: flex;
    flex-direction: column;
}

.enq-file-name {
    /* background-color: #606cf3; */
    margin-top: -2rem;
    margin-left: 2rem;
}


/*! ARROW LEGEND */


.legend {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    height: inherit;

}


.violet_bdge_legend {
    background-color: #F1F2FE;
    color: #727cf5;
    padding: 0.2rem 0.3rem;
    border-radius: 3px;
}


.grey_bdge_legend {
    background-color: #F3F3F5;
    color: #0D1432;
    padding: 0.2rem 0.3rem;
    border-radius: 3px;
}

.yellow_bdge_legend {
    background-color: #FFFAEE;
    color: #FFCF55;
    padding: 0.2rem 0.3rem;
    border-radius: 3px;
}

.green_bdge_legend {
    background-color: #E7FAF1;
    color: #0EC970;
    padding: 0.2rem 0.3rem;
    border-radius: 3px;
}

.red_bdge_legend {
    background-color: #FFEEEE;
    color: #FF5050;
    padding: 0.2rem 0.3rem;
    border-radius: 3px;
}

.myarrow {
    margin: 0 0.3rem;
    padding: 0.2rem 0;
}



/* FILE UPLOAD OVERLAY */

.file-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 80vh;
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);

    z-index: 3000;
}


.file-overlay img {
    z-index: 9999;
    opacity: 1;
}

/* RESEND OTP */

.otp-container span, button, p{
    font-family: nunito !important;
}

.resend-otp-holder>div>span>span {
    color: #757575;
    font-size: 14px;
}

.resend-otp-holder>div>button>span {
    color: #727cf5;
    font-size: 16px;
}

.otp-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: inherit;
}

.otp-container button,
span {
    all: unset;
    cursor: pointer;
    /* color: #727cf5; */
   
}

/* customer form answer list */
.small-font {
    font-size: 0.8rem !important;

}

.no-answers-container {
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-answer-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 3rem;
}

.no-answer-text p {
    line-height: 0.5;

}

.no-answer-text #text-1 {
    font-size: 1.3rem;
    font-weight: 600;
}

.no-answer-text #text-2 {
    font-size: 0.8rem;
    font-weight: 400;
}

.attachment-container {
    /* background-color: #0EC970; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
}

.attachment-text {
    /* background-color: #606cf3; */
    display: flex;
    /* justify-content: flex-start;
    align-items: flex-start; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.attachment-text p {
    line-height: 0;
}

.attachment-image {
    /* background-color: #FF5050; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    height: 100%;
    border: 3px dashed #D2D2D2;
    border-radius: 5px;
    padding: 1rem;
}

.image-span span {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    /* background-color: #FF5050; */
}

/* JOBS SECTION */

.drop-down-holder {
    /* background-color: #0EC970; */
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
}

.job-status {
    width: 10.5rem;
}


/* NOTIFICATION BADGE CSS CHANGES */

.notif-height {
    max-height: 300px;
}

.isViewAll {
    max-height: 70vh;
}

#dropdown-notification {
    border: none !important;
    box-shadow: none !important;
}

.noti-title {
    border-bottom: 1px solid #D2D2D2 !important;
}

.notification-list .noti-icon {
    font-size: 25px !important;
}

.dripicons-bell :before {
    content: "X" !important;
    height: 25px !important;
    width: 25px !important;
}

.noti-icon-badge {
    top: 21px !important;
    right: -10px !important;
    border-radius: 50%;
    height: 18px !important;
    width: 28px !important;
    border-radius: 500px !important;
    line-height: 1.8;
    text-align: center;
    background-color: #ff5959 !important;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;

}

.single-notif-card {
    width: 100%;
    background-color: white;
    /* border-top: 1px solid #D2D2D2 !important; */
    border-bottom: 1px solid #D2D2D2 !important;

}

.notif-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 0.8rem;

}

.notif-img img {
    height: 50px;
    width: 50px;
}

.notif-unread {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #727CF5;
    position: absolute;
    left: 0.5rem;
}

.downIcon {
    height: 10px;
    width: 10px;
    margin-left: 5px;
}

.rotateDownIcon {
    transform: rotate(180deg);
}

.no-notif-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-notifIcon {
    /* height: 250px;
    width: 250px; */
}

.no-notifText {
    position: relative;
    z-index: 100;
    top: -0.5rem;
    font-size: 18px;
}

.clear-btn {
    all: unset;
    background: none !important;
    box-shadow: none !important;
    color: #ff5959;
}

.clear-btn:hover {
    color: #ef3030;
}

/* CUSTOM TABLE */

.star-img img {
    height: 13px !important;
    width: 13px !important;
    margin-bottom: 4px;
    margin-left: -2px;
}

/* SELECTED SKU */

.skuExist {
    display: block;
    border: 1px solid #ccc;
    padding: 0.5rem;
    margin-top: 6px;
}

.custom-btn {
    width: 8rem !important;
    color: white;

}



/* GANTT-CHART STYLINGS */

.circle::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    margin-right: 2px;
}

.gantt-duration {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn{
    font-weight: 800;
}